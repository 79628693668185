.blog-content{
    padding: 50px 200px;
}
@media (max-width:1024px) and  (min-width:425px){
    .blog-content{
    padding: 30px 70px;
    }
}
@media  (max-width:424px){
    .blog-content{
    padding: 10px 25px;
    }
}
.footer {
    padding: 2vw 10vw;
    color: #ddd;
    font-size: medium;
    line-height: 1.7;
    position: relative;
    bottom: 0;
    background-color: #1a2988;
}

.footer-icon {
    height: 4vw;
}
hr{
    background-color: rgb(224 224 229);;
}
.social-media {
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 1vw 1vw;
    transform: scale(1.2);
    margin: 1vw;
}

.social_media_icon {
    color: rgb(255, 199, 32);
}

@media (max-width:425px) {
    .footer-icon {
        height: 10vw !important;
    }
}

@media (min-width:1600px) {
    .footer a {
        font-size: medium;
    }

    .footer_text,
    .footer a {
        font-size: 1vw;
    }

    .footer_social_media {
        transform: scale(2);
        padding-left: 10vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
    }
}

.footer_services {
    color: rgb(255, 199, 32);
}
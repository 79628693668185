.thinking-beyond-web-design {
    background: #1a2988;
    line-height: 40px;
}

.thinking-beyond-web-design-right-part {
    padding: 50px;
    margin: auto;
}

.web-design-btn {
    background: white;
    background: linear-gradient(to left, white 50%, #79d121 50%) right;
    background-size: 200%;
    position: relative;
    top: 2px;
}
.section-description{
    line-height: 25px;
}
.web-design-btn-txt {
    color: #1a2988;
}
@media (max-width:425px) {
    .thinking-beyond-web-design {
        line-height: 30px;
    }

    .thinking-beyond-web-design-right-part {
        padding: 30px;
    }
}
.project-banner {
    /* background-image: url(); */
}

.project-contact-us {
    padding: 50px 180px;
    background-color: #ebedf7;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

.project-title {
    position: relative;
    top: -100px;
    padding-left: 20px;
}

.project-title:hover {
    /* color: aqua; */
    text-shadow: 2px 2px #17235f;
}

.project-list {
    padding: 50px 50px 10px;
}

.p2 {
    margin-top: 50px;
}

@media (max-width:425px) {
    .project-contact-us {
        padding: 30px 10px;
    }

    .p2 {
        margin-top: 0;
    }

    .project-list {
        padding: 50px 20px 10px;
    }

}

@media (min-width:426px) and (max-width:1024px) {
    .project-contact-us {
        padding: 50px 80px;
    }

    .p2 {
        margin-top: 0;
    }

}
.contact-banner {
    /* background-image: url; */
}
.contact-page{
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.contact_form {
    padding: 50px 30px;
    /* width: 100%; */
}

.contact-details {
    background-color: #ebedf7;
    padding: 50px 30px;
}

.contact_form :where(.css-dev-only-do-not-override-mxhywb).ant-btn.ant-btn-lg,
.contact_form :where(.css-mxhywb).ant-btn-primary {
    background: #79d121;
    color: white;
    transform: scale(1.1);
    padding: 0 50px;
}

.contact_form :where(.css-dev-only-do-not-override-mxhywb).ant-btn.ant-btn-lg:hover,
.contact_form :where(.css-mxhywb).ant-btn-primary:hover {
    transform: scale(1.2);
}

.contact_form :where(.css-dev-only-do-not-override-mxhywb).ant-form-item .ant-form-item-label>label {
    height: 25px;
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.contact_form :where(.css-dev-only-do-not-override-mxhywb).ant-checkbox+span {
    padding-inline-start: 0px;
}

.contact_form .ant-select-show-search:where(.css-mxhywb).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0;
    color: #1a2988;
    font-size: 18px;
    padding: 8px;
    font-weight: 500;
    background-color: #ebedf7;
    border-radius: 5px;
}

.contact_form Input,
.contact_form TextArea,
.contact_form  :where(.css-1vtf12y).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 0;
    color: #1a2988;
    font-size: 18px;
    padding: 8px;
    font-weight: 500;
    background-color: #ebedf7;
    border-radius: 5px;
}

@media (max-width:768px) {
    .contact_form :where(.css-dev-only-do-not-override-mxhywb).ant-form-item .ant-form-item-label>label {
        font-size: 14px;
    }

    .contact_form :where(.css-dev-only-do-not-override-mxhywb).ant-btn.ant-btn-lg .contact_form :where(.css-mxhywb).ant-btn-primary {
        font-size: 21px;
        height: 50px;
    }

    .contact_form {
        padding: 30px;
    }

    .contact_form :where(.css-dev-only-do-not-override-mxhywb).ant-btn.ant-btn-lg,
    .contact_form :where(.css-mxhywb).ant-btn-primary {
        transform: scale(1);
    }

    .contact_form :where(.css-dev-only-do-not-override-mxhywb).ant-btn.ant-btn-lg:hover,
    .contact_form :where(.css-mxhywb).ant-btn-primary:hover {
        transform: scale(1.1);
    }
}
@font-face {
  font-family: avenir;
  src: url(./Avenir\ Next\ LT\ Pro\ Regular.otf);
}
:where(.css-dev-only-do-not-override-1vtf12y)[class^="ant-col"], :where(.css-dev-only-do-not-override-1vtf12y)[class*=" ant-col"],
:where(.css-1vtf12y)[class^="ant-col"], :where(.css-1vtf12y)[class*=" ant-col"]{
  font-family: avenir !important;
}
.app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #0e2357;
  background-color: white;
  max-width: 1600px;
  margin: 0 auto;
  font-family: avenir !important;
}
.page-banner {
  padding: 150px 200px 120px;
  background-image: url(./img/pageBanner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
font-style: italic;
}

a {
  text-decoration: none;
  color: white;
}

.center {
  text-align: center;
}

.white-color {
  color: white;
}

.yellow-color {
  color: #ffcd03;
}

.darkblue-color {
  color: #183378;
}
.box-shadow{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.green-color {
  color: #62b70d;
}

.gray-color {
  color: rgba(235, 237, 247, .7);
}
.white-blue-color{
  /* color: rgb(224 224 229); */
  color: white;
}
gray1-color {
  color: gray;
}

.point-cursor {
  cursor: -webkit-grab;
  cursor: grab;
}

.bold1-txt {
  font-weight: bolder;
}

.bold2-txt {
  font-weight: 800;
}

.bold3-txt {
  font-weight: 600;
}

.bold4-txt {
  font-weight: 500;
}

.t1 {
  font-size: 40px;
}

.t2 {
  font-size: 35px;
}

.t3 {
  font-size: 30px;
}
.t2-withspace {
  font-size: 35px;
   /* word-spacing: 6px;
    line-height: 40px; */
}

.t4 {
  font-size: 25px;
  line-height: 30px;
}

.t5 {
  font-size: 20px;
  line-height: 28px;
}

.t6 {
  font-size: 16px;
  line-height: 25px;
}
.t7 {
  font-size: 14px;
  line-height: 20px;
}
.mt {
  margin-top: 10px;
}

.mr {
  margin-right: 10px;
}

.mb0 {
  margin-bottom: 0;
}

.ml {
  margin-left: 10px;
}

.mt2 {
  margin-top: 20px;
}

.mb {
  margin-bottom: 10px;
}

.mb2 {
  margin-bottom: 20px;
}
.ptb2{
  padding: 20px 0;
}
.plr2 {
  padding-left: 20px;
  padding-right: 20px;
}

button {
  border: none;
}

.submit-btn {
  padding: 12px 34px;
  position: relative;
  top: 12px;
  border-radius: 10px;
  display: inline-block;
  background: #ffcd03;
  background: linear-gradient(to left, #ffcd03 50%, #79d121 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
  font-family: avenir !important;
} 

.submit-btn:hover {
  background-position: left;
}
.blue-white-btn {
  padding: 12px 34px;
  position: relative;
  top: 12px;
  border-radius: 10px;
  display: inline-block;
  background: #183378;
  background: linear-gradient(to left, #183378 50%, #79d121 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
  font-family: avenir !important;
}

.blue-white-btn:hover {
  background-position: left;
}


@media (max-width:425px) {
  .page-banner {
    padding: 70px 20px 50px;
  }

  .t1 {
    font-size: 30px;
  }

  .t2 {
    font-size: 28px;
  }
  .t2-withspace {
    font-size: 30px;
     word-spacing: 4px;
      line-height: 35px;
  }

  .t3 {
    font-size: 25px;
  }

  .t4 {
    font-size: 20px;
  }

  .t5 {
    font-size: 16px;
  }

  .t6 {
    font-size: 14px;
  }
  .t7 {
    font-size: 12px;
  }
}

@media (min-width:426px) and (max-width:1024px) {
  .page-banner {
    padding: 120px 100px 100px;
  }

  .t1 {
    font-size: 35px;
  }

  .t2 {
    font-size: 30px;
  }
  .t2-withspace {
    font-size: 32px;
     word-spacing: 5px;
      line-height: 38px;
  }
  .t3 {
    font-size: 25px;
  }

  .t4 {
    font-size: 25px;
  }

  .t5 {
    font-size: 20px;
  }

  .t6 {
    font-size: 16px;
  }
  .t7 {
    font-size: 14px;
  }
  .submit-btn {
    padding: 12px 30px;
  }
  .blue-white-btn{
    padding: 12px 30px;
  }
}
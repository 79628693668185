.data-creativity-content { 
    padding: 50px;
    margin: auto;
}

.aboutus-leftright-arrow {
    color: white;
}

.aboutus-leftright-arrow:hover {
    color: #62b70d;
}
#free-consulation{
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.aboutus-slider {
    padding: 60px 100px;
    background-image: url(../../img/Home/sliderBg.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-style: italic;
}

.we-are-different {
    padding: 50px 100px;
}

.about-us-contact {
    background-color: #ebedf7;
    padding: 0px 80px;
}

#about-us-contact-form Input,
#about-us-contact-form TextArea,
#about-us-contact-form .ant-select-show-search:where(.css-dev-only-do-not-override-mxhywb).ant-select:not(.ant-select-customize-input) .ant-select-selector,
#about-us-contact-form :where(.css-1vtf12y).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: white;
}

@media (min-width:426px) and (max-width:1024px) {
    .aboutus-slider {
        padding: 100px 50px;

    }

    .we-are-different {
        padding: 50px 50px;
    }

    .about-us-contact {
        padding: 50px 50px 0;
    }
}

@media (max-width:425px) {
    .data-creativity-content {
        padding: 30px;
    }

    .aboutus-slider {
        padding: 50px 10px;

    }

    .we-are-different {
        padding: 50px 30px;
    }

    .about-us-contact {
        padding: 30px 0px 0;
    }
}
.services-web-design-btn {
    background: #1a2988;
    background: linear-gradient(to left, #1a2988 50%, #79d121 50%) right;
    background-size: 200%;
    position: relative;
    top: 2px;
}

.services-web-design-btn-txt {
    color: white;
}

.plus-all-services {
    padding: 50px 100px;
    background-color: rgba(235, 237, 247, .7);
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

.webdesign-process {
    padding: 50px;
    background-color: white;
}

.number {
    padding: 8px 18px;
    background-color: #1a2988;
    color: white;
    margin-right: 15px;
    border-radius: 10px;
    font-weight: 500;
}

@media (max-width:425px) {
    .plus-all-services {
        padding: 30px 40px;
    }

    .number {
        padding: 2px 12px;
        margin-right: 8px;
    }
}

@media (min-width:426px) and (max-width:1024px) {
    .plus-all-services {
        padding: 40px 60px;
    }
}
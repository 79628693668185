/* .basic-card:hover {
    background-color: rgb(219, 250, 161);
} */
.basiccard {
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

.service-icon {
    /* padding: 40px 100px 10px; */
}

.icon-size {
    height: 150px;
    width: 150px;
    margin: auto;
}

@media (max-width:1024px) and (min-width:426px) {
    .service-icon {
        /* padding: 0 80px; */
    }
}

@media (max-width:425px) {
    .service-icon {
        /* padding: 0 50px; */
    }
}
.home-banner {
    background-image: url(../../img/Home/banner1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    padding: 200px 300px 250px;
    /* font-style: italic; */
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.home-banner-txt{
font-size: 50px;
margin-bottom: 35px;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-modal .ant-modal-content,
:where(.css-1vtf12y).ant-modal .ant-modal-content {
    background-color: black;
    padding: 60px 40px 40px 40px;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-modal .ant-modal-close,
:where(.css-1vtf12y).ant-modal .ant-modal-close-x {
    color: white;
    font-size: large;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-modal .ant-modal-close:hover {
    color: white;
}

.hb1 {
    background: #ebedf7;
    padding: 50px 220px 80px;
    font-style: italic;
    font-weight: bolder;
    margin-top: -158px;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important; */
}

.wave {
    position: relative;
    top: -150px;
}

.project1 {
    background: url(../../img/Home/project2blackandwhite.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0.8;
    margin-bottom: -1px;
}

.project1:hover {
    background: url(../../img/Home/project2.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.project2:hover {
    background: url(../../img/Home/project1.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.project2 {
    background: url(../../img/Home/project1blackandwhite.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0.8;
    margin-bottom: -1px;
}

.blogs-info {
    padding: 60px 200px;
    background-color: #ebedf7;
}

.blogs {
    display: flex;

}

.b1 {
    background: url(../../img/blogs/pexels-pixabay-new.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.b2 {
    background: url(../../img/blogs/smsm-new.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.b3 {
    background: url(../../img/blogs/dm1-new.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.b4 {
    background: url(../../img/blogs/smm1-new.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.b5 {
    background: url(../../img/blogs/seo1-new.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.blog-title {
    position: relative;
    top: 150px;
}
.b1-new,.b2-new,.b3-new,.b4-new,.b5-new{
    display: none;
}
.b1-new{
    background: url(../../img/blogs/pexels-pixabay.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.b2-new {
    background: url(../../img/blogs/smsm.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.b3-new {
    background: url(../../img/blogs/dm1.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.b4-new {
    background: url(../../img/blogs/smm1.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.b5-new {
    background: url(../../img/blogs/seo1.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    height: 220px;
    padding: 20px;
}

.project-section {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    padding: 50px 40px 30px;
}


.project-info {
    text-align: center;
    background-color: #ebedf7;
    padding: 40px 100px 20px;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important */
}

.services {
    padding: 30px 50px 40px;
    text-align: center;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important */
}

.ready-to-start {
    background-color: #1a2988;
    color: white;
    padding: 40px 30px;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important */
}

@media (min-width:769px) and (max-width:1024px) {
    .home-banner-txt{
        font-size: 35px;
        margin-bottom: 25px;
        }
    .blog-title {
        top: 100px;
    }

    .services {
        padding: 50px 20px 30px;
    }

    .blogs-info {
        padding: 30px;
    }

    .hb1 {
        padding: 50px 100px;
    }

    .project-section {
        padding: 30px 60px 30px;
    }

    .home-banner {
        padding: 200px 153px 250px;
    }

}

@media (max-width:768px) and (min-width:426px) {
    .home-banner-txt{
        font-size: 32px;
        margin-bottom: 22px;
        }
    .blogs-info {
        padding: 30px;
    }

    .home-banner {
        padding: 150px 100px 200px;
    }

    .services {
        padding: 50px 20px 30px;
    }

    .hb1 {
        padding: 20px 100px 30px;
    }

    .project-section {
        padding: 25px 15px 15px;
    }
}

@media (max-width:425px) {
    .home-banner-txt{
        font-size: 20px;
        margin-bottom: 10px;
        }
    .blogs-info {
        padding: 30px;
    }

    .home-banner {
        padding: 80px 10px 180px
    }

    .services {
        padding: 50px 20px 30px;
    }

    .hb1 {
        padding: 30px 19px 40px;
    }

    .project-section {
        padding: 100px 15px 100px;
    }

    .project-info {
        padding: 20px 15px 20px;
    }

    .ready-to-start {
        padding: 30px;
    }
}
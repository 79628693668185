.nav-bar {
    display: grid;
    grid-template-columns: 2% 15% 78%;
    justify-items: end;
    position: fixed;
    width: 100%;
    z-index: 20;
    align-items: center;
    justify-content: space-between;
    max-width: 1600px;
    margin: 0 auto;
}

.nav-bar-blue-color {
    background-color: #1a2988;
}

.nav-bar-transparent-color {
    background-color: transparent;
}

.nav-bar nav ul {
    list-style: none;
}

.nav-bar nav ul li {
    position: relative;
    float: left;
}

.nav-bar nav ul li a {
    padding: 0.5vw 1vw;
    display: block;
    color: white;
    font-size: medium;
    font-weight: bold;
    text-decoration: none;
}

.nav-bar nav ul li a:hover {
    color: #ffc720;
    border-bottom: 1px solid #ffc720;
}
@-webkit-keyframes buzz-out-on-hover {
    10% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    20% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    30% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    40% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    50% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    60% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    70% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    80% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    90% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0);
    }
    100% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0);
    }
  }
  @keyframes buzz-out-on-hover {
    10% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    20% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    30% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    40% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    50% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    60% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    70% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    80% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    90% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0);
    }
    100% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0);
    }
  }
  .buzz-out-on-hover {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .buzz-out-on-hover:hover, .buzz-out-on-hover:focus, .buzz-out-on-hover:active {
    -webkit-animation-name: buzz-out-on-hover;
    animation-name: buzz-out-on-hover;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
  
.nav-bar nav ul li ul {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 200px;
    padding: 0;
    list-style: none;
    display: none;
}

.nav-bar nav ul li ul li {
    background: white;
    width: 100%;
    padding: 1vw 1vw 0vw;
}

.nav-bar nav ul li ul li a {
    color: #1a2988;
    border-bottom: none !important;
}

.nav-bar nav ul li:hover>ul {
    display: inline;
}

#menu-bar {
    display: none;
}

label {
    font-size: 9vw;
    cursor: pointer;
    display: none;
    margin-right: 4vw;
}

@media (min-width:1600px) {

    .nav-bar nav ul li ul {
        width: 600px;
    }

}

@media (max-width: 600px) {
    .nav-bar nav ul li a:hover {
        border-bottom: none;
    }
    .nav-bar{
        padding: 14px 6px 0;
    }
    .header_icon {
        padding-left: 15vw;
        transform: scale(2.2);
        margin-top: -2vw;
    }

    .nav-bar nav ul li a {
        font-size: 4vw !important;
        padding: 2vw 0 !important;
    }

    label {
        display: initial;
    }

    .nav-bar nav {
        position: absolute;
        top: 10vw;
        left: 0;
        right: 0;
        background: white;
        border-top: 1px solid black;
        display: none;
    }

    .nav-bar nav ul li {
        width: 100%;
    }

    .nav-bar nav ul li ul {
        position: relative;
        width: 100%;
    }

    #menu-bar:checked~nav {
        display: initial;
        background-color: #1a2988;
        margin-top: 13px;
    }

    .nav-bar nav ul li ul li {
        background: #1a2988;
    }

    .nav-bar nav ul li ul li a {
        color: white;
    }
}